h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

.section-title {
  padding-bottom: 10px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-bottom: 10px;
  position: relative;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #5b5b5b;
  bottom: 0;
  left: 0;
}

#hero {
  width: 100%;
  height: 100vh;
  background: url("../image/bgg.jpg") top center;

  background-size: cover;
  position: relative;
}

#con {
  background: url("../image/unlogo.png") no-repeat center;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.643);

  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
  padding-top: 100px;
  text-align: center;
}

#hero h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
}

#hero .icon-box {
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.615);
  height: 100%;
  border-radius: 8px;
  background-color: #8b8b8b3d;
  text-align: center;
}

#hero .icon-box:hover {
  background-color: #1d1d1da2;
}

#hero .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: #fff;
}

#hero .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
}

#hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

/* #hero .icon-box:hover {
     border-color: #04C3FF;
   }  */

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero {
    height: auto;
  }
  #hero h1 {
    font-size: 29px;
    line-height: 36px;
    margin-top: 70px;
  }
  #hero h2 {
    font-size: 16px;
    line-height: 24px;
  }

  #hideeee {
    display: none;
  }
}

/*--------------------------------------------------------------
# collab
--------------------------------------------------------------*/
.collab {
  padding-top: 20px;
}

@media (max-width: 405px) {
  .collab img {
    min-width: 10vh;
  }
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding-top: 20px;
}

.clients #clienttext {
  background-color: #d2e4f9;
  padding-top: 10px;
  margin-top: 1px;
  height: 70px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 4px 4px 10px rgba(167, 167, 167, 0.25);
}

.clients h5 {
  font-weight: bold;
}

.clients p {
  margin-top: -5px;
  font-size: 11px;
}

.clients img {
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgba(167, 167, 167, 0.25);
}

@media (min-width: 350px) and (max-width: 400px) {
  .clients #clienttext {
    width: 18vh;
    height: 62px;
  }

  .clients img {
    width: 18vh;
    height: auto;
  }

  .clients h5 {
    font-size: 14px;
  }
  .clients p {
    font-size: 10px;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  .clients #clienttext {
    width: 21vh;
  }

  .clients img {
    width: 21vh;
    height: auto;
  }

  .clients h5 {
    font-size: 12px;
  }

  .clients p {
    font-size: 10px;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  .clients #clienttext {
    width: 28vh;
  }

  .clients img {
    width: 28vh;
    height: auto;
  }

  .clients h5 {
    font-size: 16px;
  }

  .clients p {
    font-size: 12px;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .clients #clienttext {
    width: 35vh;
  }

  .clients img {
    width: 35vh;
    height: auto;
  }

  .clients h5 {
    font-size: 18px;
  }

  .clients p {
    font-size: 12px;
  }
}

@media (min-width: 800px) and (max-width: 1500px) {
  .clients #clienttext {
    width: 35vh;
  }

  .clients img {
    width: 35vh;
    height: auto;
  }

  .clients h5 {
    font-size: 18px;
  }

  .clients p {
    font-size: 12px;
  }
}

.features #images {
  transition: all ease-in-out 0.3s;
}

.features #images:hover {
  transform: translateY(-10px);
}

.blog {
  position: relative;

  border: 1px solid rgb(233, 233, 233);
  margin-top: 20px;
}
.blog-image {
  overflow: hidden;
  margin-top: -10px;
}
.blog-image,
.blog-image > a,
.blog-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}
.blog-image img {
  -moz-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
.blog-image a:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.blog-image {
  margin-bottom: 30px;
}
.blog-content {
  position: relative;
  padding: 10px;
}
.blog-title {
  padding: 15px;
  height: 15vh;
}

.blog-title h3 {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

.blog-title p {
  color: #fff;
  font-size: 12px;
}
.read-more {
  color: #282628;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 20px;
}
.read-more:hover {
  color: #009efb;
  margin-left: 10px;
  -moz-transition: all 0.2s linear 0s;
  -ms-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}
.read-more i {
  color: #009efb;
  font-size: 18px;
  margin-right: 3px;
}
.blog-info {
  border: 1px solid #eaeaea;
  color: #909090;
  font-size: 12px;
  margin-bottom: 0;
  padding: 12px 20px;
}
.blog-info a {
  color: #909090;
  margin-right: 5px;
}
.blog-info a:last-child {
  margin-right: 0;
}
.blog-info a:hover {
  color: #009efb;
}
.blog-info i {
  color: #009efb;
  font-size: 18px;
  margin-right: 5px;
}
.post-left {
  float: left;
}
.post-right {
  float: right;
}
.post-left ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.post-left ul li {
  float: left;
  margin-right: 20px;
}
.post-left ul li:last-child {
  margin-right: 0;
}
.video {
  position: relative;
  padding-bottom: 56%;
  height: 0;
  overflow: hidden;
}
.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.widget {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e7e7e7;
}
.widget h5 {
  color: #656565;
  font-size: 21px;
  font-weight: normal;
  margin: 0 0 20px;
}
.search-form .form-control {
  border: 1px solid #eaeaea;
  border-radius: 0;
  border-right: 0;
  box-shadow: inherit;
  height: 40px;
  color: #ababab;
}
.search-form .btn-primary {
  background-color: transparent;
  border-color: #eaeaea;
  border-left: none;
  color: #009efb;
  height: 40px;
  border-radius: 0;
}
.latest-posts {
  margin: 0;
  padding: 0;
}
.latest-posts li {
  display: table;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}
.latest-posts li:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}
.post-thumb {
  width: 80px;
  float: left;
  overflow: hidden;
}
.post-thumb a img {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.post-thumb a:hover img {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.post-info {
  margin-left: 95px;
}
.post-info h4 {
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  margin: 0 0 10px;
}
.post-info h4 a {
  color: #333;
}
.post-info p {
  color: #909090;
  font-size: 12px;
  margin: 0;
}
.post-info p i {
  color: #009efb;
  font-size: 16px;
  margin-right: 4px;
}
.categories {
  list-style: none;
  margin: 0;
  padding: 0;
}
.categories li {
  padding: 12px 0;
  border-bottom: 1px solid #eaeaea;
}
.categories li:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}
.categories li a {
  color: #656565;
  font-size: 14px;
}
.categories li a:hover {
  color: #009efb;
  margin-left: 10px;
  -moz-transition: all 0.2s linear 0s;
  -ms-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}
.categories > li > a > i {
  color: #009efb;
  font-size: 18px;
  margin-right: 10px;
}
.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.tags li {
  float: left;
}
.tag {
  background-color: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  font-size: 12px;
}
.tag:before {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}
.tag:after {
  background-color: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}
.tag:hover {
  background-color: #009efb;
  color: #fff;
}
.tag:hover:after {
  border-left-color: #009efb;
}
.comment-by {
  display: block;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 10px;
}
.comments-list .comment-block p {
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  text-align: justify;
}
blockquote p {
  font-style: italic;
  font-weight: 400;
}
.grid-blog .blog-title {
  font-size: 16px;
  margin: 0 0 10px;
}
.grid-blog .blog-title a {
  color: #333;
}
.grid-blog .blog-content p {
  font-size: 13px;
  margin: 0 0 15px;
  color: #909090;
}
.grid-blog .blog-info {
  border: 0;
  border-top: 1px solid #eaeaea;
  margin-bottom: 0;
  padding: 12px 0;
}
.grid-blog .blog-image {
  margin-bottom: 20px;
}
.grid-blog .read-more {
  font-size: 13px;
  margin-bottom: 15px;
}
.product-thumbnail {
  position: relative;
  margin-bottom: 0;
  margin-top: 10px;
}
.product-remove {
  position: absolute;
  top: -10px;
  right: -6px;
  color: #f06060;
  cursor: pointer;
}
.bootstrap-tagsinput {
  width: 100%;
  height: 40px;
  box-shadow: inherit;
  border-radius: 0;
  line-height: 30px;
}
.bootstrap-tagsinput .badge [data-role="remove"]:hover {
  box-shadow: inherit;
  color: #f00;
}

.portfolio #imggg {
  transition: all ease-in-out 0.3s;
}

.portfolio #imggg:hover {
  transform: translateY(-10px);
}

.services #icon {
  text-align: center;
  border: 1px solid #ebebeb;
  padding: 10px 10px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services #icon:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
}

.videos #icon {
  transition: all ease-in-out 0.3s;
}

.videos #icon:hover {
  transform: translateY(-10px);
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #151515;
  background: #ffc451;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.35);
  background: rgba(21, 21, 21, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(21, 21, 21, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
  border-radius: 8px;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #ffc451;
}

.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
   # Portfolio Details
   --------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #ffc451;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #ffc451;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(21, 21, 21, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*-----------------
	2. Table
-----------------------*/

.table {
  color: #000;
  border: 1px solid #f0f0f0;
}
.table.table-white {
  background-color: #fff;
}
.table > tbody > tr > td {
  font-weight: 300;
}
.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f6f6f6;
}
table.table td .avatar {
  margin: 0 5px 0 0;
}
table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
table.table td h2 a {
  color: #757575;
}
table.table td h2 a:hover {
  color: #009efb;
}
table.table td h2 span {
  color: #9e9e9e;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}

.custom-badge {
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  min-width: 95px;
  padding: 1px 10px;
  text-align: center;
}
.status-red,
a.status-red {
  background-color: #ffe5e6;
  border: 1px solid #fe0000;
  color: #fe0000;
}
.status-green,
a.status-green {
  background-color: #e5faf3;
  border: 1px solid #00ce7c;
  color: #00ce7c;
}
.status-orange,
a.status-orange {
  background-color: #fef5e4;
  border: 1px solid #ff9b01;
  color: #ff9b01;
}
.status-blue,
a.status-blue {
  background-color: #e5f3fe;
  border: 1px solid #008cff;
  color: #008cff;
}
.status-purple,
a.status-purple {
  background-color: #f3e7fd;
  border: 1px solid #8f13fd;
  color: #8f13fd;
}
.status-pink,
a.status-pink {
  background-color: #ffe5f6;
  border: 1px solid #ff01a2;
  color: #ff01a2;
}

#morebtn {
  color: white;
  font-size: 12px;
  padding: 15px;
  border-radius: 4px;
  font-weight: bold;
}

#morebtn:hover {
  background-color: #9f9f9f;
}

@media (max-width: 768px) {
  #textconvene {
    display: none;
  }
}
