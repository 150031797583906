.blogItem-wrap {
  display: flex;
  flex-direction: column;
}
.blogItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}
.blogItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;
  flex: 1;
}

.blogItem-desc {
  position: relative;
  max-height: 130px;
  overflow: hidden;
  padding-right: 0.6rem;
  font-size: 0.8rem;
  color: #a9a9a9;
}

.blogItem-desc::before {
  position: absolute;
  content: '...';
  bottom: 0;
  right: 0;
}



.blogItem-author {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.blogItem-author p {
  font-size: 0.6rem;
  color: #a9a9a9;
  font-weight: 600;
}
