


#head {
  width: 100%;
  height: 50vh;
  background: url("https://img.freepik.com/free-photo/teamwork-making-online-blog_53876-94868.jpg?t=st=1655229830~exp=1655230430~hmac=8f8eb8ac2cfe810a1b16c752dcd8eb865107d56d237bf47fc724c46a3763e623&w=1060") top center;
  background-size: cover;
  position: relative;


}

#head:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#head .container {
  position: relative;
  padding-top: 74px;
  text-align: center;
}

#head h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

#head h1 span {
  color: #ffc451;
}




@media (min-width: 1024px) {
  #head {
    background-attachment: fixed;

  }
}

@media (max-width: 768px) {
  #head {
    height: auto;
  }
  #head h1 {

    font-size: 28px;
    line-height: 36px;
 margin-top: 70px;
  }

}








